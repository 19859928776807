import React from 'react'
import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
// import ImageBanner from "../Images/background/ImageBanner.jpg"
// import img2 from "../Images/infra/infra2.jpg"
// import img3 from "../Images/infra/infra3.jpg"


import Plant from "../Components/reusable/Plant"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/style.css"


// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import ImageCarousel from '../Components/infra/ImageCarousel'

import Loadable from "react-loadable"
const loader=()=>(<div>Loading...</div>)

const MyImgCarousel = Loadable({
  loader: () => import("../Components/infra/ImageCarousel"),
  loading: loader,
})
function Infra() {
    return (
        <Layout>
           <Banner title="Our Infrastructure"/> 
    <div className="section">
		<div className="content-wrap-60">
			<div className="container">
				<div className="row mb-5">
					<div className="col-sm-12 col-md-12">
                        <MyImgCarousel />
                    
					</div>
				</div>

                <div className="row mb-5">
					<div className="col-sm-12 col-md-12">
						<p style={{textAlign:"center"}}>
                        With a state-of-the-art facility spread over 30,000 Sq.ft, our manufacturing facility is able to churn out components with a capacity of 200,000 sprocket sets per month. Equipped with high-end machinery and tools, the infrastructure is also complemented with in-house testing and tooling facilities that enable us to function smoothly.
                        </p>
					</div>
				</div>
            
                <div className="row ">
                    <div className="col-12 col-md-12">

                    <table className="table table-striped table-bordered">	
                        <tbody>		
                            <tr className="thead-light">			
                                <td><strong>Dies for Press Shops to Develop Jigs and Fixtures</strong></td>			
    		
                            </tr>		
                            <tr>			
                                <td><strong>CNC Turning Centres</strong></td>			
    		
                            </tr>		
                            <tr className="thead-light">			
                                <td><strong>Auto Cycle Turning Centres</strong></td>			
    		
                            </tr>		
                            <tr>			
                                <td><strong>NC Hobbing Machines</strong></td>			
    		
                            </tr>		
                            <tr className="thead-light">			
                                <td><strong>Multi Spindle Drilling Machines</strong></td>			
    		
                            </tr>		
                            <tr>			
                                <td><strong>Broaching Machines</strong></td>			
    		
                            </tr>		
                            <tr className="thead-light">			
                                <td><strong>Automatic Tapping Machines</strong></td>			
    		
                            </tr>		
                            <tr>			
                                <td><strong>Shot Blasting Machines</strong></td>			
    		
                            </tr>		
                            <tr className="thead-light">			
                                <td><strong>Rotary Vibero Machines</strong></td>			
    		
                            </tr>		
                            <tr>			
                                <td colspan="2"><strong>In-house Tool room facility equipped with Lathes, VMC, Milling, Surface grinding, Internal and external cylindrical grinders, Tool and Cutter grinders. Hardness Testing Machine, Profile Projector, Microscope, Run Out Testing machine, Hob Sharpening etc.</strong></td>		
                            </tr>	
                        </tbody>
                    </table>

                    </div>
                </div>



            </div>
        </div>
    </div>

    <Plant />
        </Layout>
    )
}

export default Infra

